import { makeServerQueryClient } from '@dx-ui/framework-react-query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { JoinForm } from '@dx-ui/osc-join-form';

import { Headline } from '../../../components/Headline/Headline';
import { MetricsContext } from '../../../config/metrics';
import { REACT_QUERY_CONFIG } from '../../../config/react-query';
import { useLanguage } from '../../../hooks';
import { auth } from '../../../utils/auth';
import { ENABLE_MARKETABILITY_CCS_CONTENT } from '../../../utils/constants/featureFlags';
import { GLOBAL_RESOURCE_BUNDLES } from '../../../utils/constants/global-resource-bundles';
import { minimalLayout } from '../../../utils/constants/layouts';
import {
  isFeatureEnabled,
  useServerSideFeatureToggles as UseServerSideFeatureToggles,
} from '../../../utils/featureToggles';
import { serverSideProps } from '../../../utils/helpers/serverSideProps';
import { show404NonTransPage } from '../../../utils/helpers/show404NonTransPage';

import type { CreateGuestMutationVariables } from '../../../types/generated/types';
import { env } from '@dx-ui/framework-env';
import { Head } from '../../../components/Head/Head';
import {
  serverSideBrand_CountriesQuery,
  useFeatureTogglesQuery,
} from '../../../queries/generated/react-query';
import { sendReward } from '@dx-ui/framework-conductrics';
import { useConductrics } from '../../../hooks/useConductrics';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

export const oCodes = [
  'ADAPW',
  'ANDEW',
  'BRDEW',
  'CIHCW',
  'CNMP',
  'DSKEW',
  'DTITW',
  'ESHCW',
  'HHHTW',
  'HHPCW',
  'HITEW',
  'HPHCW',
  'IOSEW',
  'JNHHW',
  'NHBHW',
  'NMUPW',
  'OHWBW',
  'OHWE',
  'OHWGW',
  'PYHTW',
  'QQHTW',
  'RUHCW',
  'WAHCW',
  'WFPAW',
  'JHTNW',
  'ASPEW',
  'SMBEW',
  'PEPEW',
];

export type JoinInput = Omit<
  CreateGuestMutationVariables['input'],
  'username, propCode, country'
> & {
  country: string;
  marketingOffers: boolean;
  marketingConsent?: boolean;
  marketingGrandVacations?: boolean;
};

export const Join: NextPage<typeof getServerSideProps> = () => {
  const language = useLanguage();
  const router = useRouter();

  const { data: featureToggleData, error } = useFeatureTogglesQuery({
    flags: [ENABLE_MARKETABILITY_CCS_CONTENT],
  });

  if (error) {
    logError(
      'HILTON_HONORS_JOIN',
      generateError(error),
      `Error on ${useFeatureTogglesQuery.name} | flags: ${[ENABLE_MARKETABILITY_CCS_CONTENT]}`
    );
  }

  const oCode = useMemo(() => {
    const oCodeParam = Object.keys(router.query).find((param) => /^ocode$/i.test(param));
    return (oCodeParam && (router.query?.[oCodeParam] as string)) || 'OHWGW';
  }, [router]);
  useConductrics();

  const isMarketabilityCCSEnabled = isFeatureEnabled(
    featureToggleData?.featureToggles ?? [],
    ENABLE_MARKETABILITY_CCS_CONTENT
  );

  const { metrics } = useContext(MetricsContext);

  const [t] = useTranslation('join');

  return (
    <>
      <Head title={t('title')} keywords={t('meta_keywords')} description={t('meta_description')} />
      <JoinForm
        language={language}
        oCode={oCode}
        isMarketabilityCCSEnabled={isMarketabilityCCSEnabled}
        metrics={metrics}
        headline={<Headline className="m-auto mb-8 text-center">{t('joinHHonors')}</Headline>}
        origin={new URL(env('OHW_BASE_URL')).origin}
        onSuccess={async (data) => {
          const hHonorsNumber = data?.createGuest?.data?.hhonorsNumber;
          sendReward('g-XuedMeNnr5');
          if (oCode === 'SMBEW') {
            await router.push(
              `${router?.query?.forwardPageURL}${
                router?.query?.forwardPageURL?.includes('forwardPageURI')
                  ? `&hHonorsNumber=${hHonorsNumber}`
                  : `/?hHonorsNumber=${hHonorsNumber}`
              }`
            );
          } else if (oCode === 'PEPEW') {
            await router.push(
              `/${language}/hilton-honors/points-explorer/?hHonorsNumber=${hHonorsNumber}`,
              undefined,
              { shallow: true }
            );
          } else {
            window.location.assign(
              `/${language}/hilton-honors/join/confirmation/?hHonorsNumber=${hHonorsNumber}&enrollOffer=false`
            );
          }
        }}
      />
    </>
  );
};

export const getServerSideProps = (async (context) => {
  const { req, res, locale } = context;
  const authClient = auth(req as unknown as Request);
  const queryClient = makeServerQueryClient({
    ...REACT_QUERY_CONFIG,
    serverResponse: res,
    authClient,
  });

  const show404 = await show404NonTransPage({ url: req.path, language: locale, queryClient });
  if (show404) return { notFound: true };

  const featureToggles = await UseServerSideFeatureToggles(queryClient, [
    ENABLE_MARKETABILITY_CCS_CONTENT,
  ]);

  try {
    await serverSideBrand_CountriesQuery(queryClient, {
      language: locale,
      getMarketing: true,
      getCCSMarketing: featureToggles?.[0]?.enabled,
      countryFilter: { prohibited: false },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      '[dx-guests-ui] hilton-honors/join/index.tsx - error getting brand_countries',
      error
    );
  }

  return serverSideProps({
    authClient,
    context,
    queryClient,
    pageProps: {
      layout: minimalLayout,
      metrics: {
        trackingData: {
          pageView: 'honorsPageView',
          pageData: {
            pageName: 'join',
            subSection: 'brand',
            subSubSection: '',
            pageType: 'join',
          },
        },
      },
    },
    namespaces: [
      ...GLOBAL_RESOURCE_BUNDLES,
      'join',
      'form',
      'osc-join-form-join',
      'osc-join-form-form',
      'osc-join-modal-button',
    ],
  });
}) satisfies GetServerSideProps;

export default Join;
